var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":_vm.$vuetify.breakpoint.md ? '75%' : _vm.$vuetify.breakpoint.lg ? '75%'
          : _vm.$vuetify.breakpoint.sm ? '60%' : _vm.$vuetify.breakpoint.xl  ? '75%' : '100%',"persistent":""},model:{value:(_vm.synced_is_open),callback:function ($$v) {_vm.synced_is_open=$$v},expression:"synced_is_open"}},[_c('v-card',{attrs:{"rounded":"lg","min-height":"90vh","loading":_vm.add_custom_goal_wizard_model.is_loading}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"accent relative d-none d-md-block pb-md-0",attrs:{"cols":"5"}},[_c('v-row',{staticClass:"mt-0 h-full"},[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('img',{staticClass:"mt-12 mb-3",attrs:{"src":require('@/assets/images/logo-medium-white.png'),"alt":_vm.add_custom_goal_wizard_model.translate('alts.icon_logo_medium_white'),"height":"21px","draggable":"false"}}),_c('h6',{staticClass:"white--text mb-4"},[_vm._v(" "+_vm._s(_vm.add_custom_goal_wizard_model.translate('title'))+" ")]),_c('ul',{staticClass:"pl-0"},_vm._l((_vm.add_custom_goal_wizard_model.step_names),function(step,index){return _c('li',{key:index,class:("d-flex align-center text-text mb-3\n                     " + (_vm.add_custom_goal_wizard_model.current_step >= index
                    ? 'secondary' : 'dark-grey') + "--text")},[_c('div',{staticClass:"d-flex justify-center align-center mr-5 check-icon",class:_vm.add_custom_goal_wizard_model.current_step > index
                     ? 'secondary' : 'dark-grey'},[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-check")])],1),_c('span',[_vm._v(_vm._s(step))])])}),0)]),_c('v-col',{staticClass:"pa-0 text-center d-flex align-end",attrs:{"cols":"12","align-self":"end"}},[_c('div',{staticClass:"w-full"},[_c('img',{staticClass:"mb-n2",attrs:{"src":require('@/assets/icons/goals.svg'),"alt":_vm.add_custom_goal_wizard_model.translate('alts.icon_goals'),"draggable":"false","aria-hidden":"true"}}),_c('div',{staticClass:"mountain-icon"})])])],1)],1),_c('v-col',{staticClass:"white",attrs:{"cols":"12","md":"7"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-5 pa-0",attrs:{"cols":"12"}},[_c(_vm.add_custom_goal_wizard_model.current_component,{tag:"component",attrs:{"custom-goal-selected":_vm.add_custom_goal_wizard_model
                  .custom_investor_goal_dto,"associated-product-id":_vm.add_custom_goal_wizard_model.associated_product_id,"goals-selected":_vm.add_custom_goal_wizard_model.goals_selected,"investor-goals":_vm.add_custom_goal_wizard_model.investor_goals,"goals-amount-information":_vm.add_custom_goal_wizard_model.goals_amount_information,"new-recurrent-amount":_vm.add_custom_goal_wizard_model.new_recurrent_amount},on:{"nextStep":_vm.add_custom_goal_wizard_model.nextStep,"prevStep":_vm.add_custom_goal_wizard_model.prevStep,"endProcess":_vm.endProcess,"creationGoalFlowCompleted":_vm.creationGoalFlowCompleted,"loadingStep":_vm.add_custom_goal_wizard_model.changeLoadingStep,"setCustomGoalSelected":_vm.add_custom_goal_wizard_model.setCustomGoalSelected,"setCustomGoalInvestorDto":function($event){_vm.add_custom_goal_wizard_model
                  .setCustomGoalInvestorDto},"changeInvestorGoals":_vm.add_custom_goal_wizard_model.changeInvestorGoals,"changeGoalsAmountInformation":_vm.add_custom_goal_wizard_model.changeGoalsAmountInformation,"changeRecurrentAmount":_vm.add_custom_goal_wizard_model.changeRecurrentAmount,"changeRecurrentUnassignedAmount":_vm.add_custom_goal_wizard_model.changeRecurrentUnassignedAmount,"changeDefineContributionLater":_vm.add_custom_goal_wizard_model.changeDefineContributionLater,"update:customGoalSelected":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model
                  , "custom_investor_goal_dto", $event)},"update:custom-goal-selected":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model
                  , "custom_investor_goal_dto", $event)},"update:associatedProductId":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "associated_product_id", $event)},"update:associated-product-id":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "associated_product_id", $event)},"update:goalsSelected":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "goals_selected", $event)},"update:goals-selected":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "goals_selected", $event)},"update:investorGoals":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "investor_goals", $event)},"update:investor-goals":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "investor_goals", $event)},"update:goalsAmountInformation":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "goals_amount_information", $event)},"update:goals-amount-information":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "goals_amount_information", $event)},"update:newRecurrentAmount":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "new_recurrent_amount", $event)},"update:new-recurrent-amount":function($event){return _vm.$set(_vm.add_custom_goal_wizard_model, "new_recurrent_amount", $event)}}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }