import { v4 } from 'uuid';
import TYPES from '@/types';

// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import { InvestorGoalsDto } from '@/modules/flagship/investor-goal/link-sowos-wealth/domain/dtos/investor-goals-dto';
import {
  CustomerContractedInvestmentProductsStateManager,
} from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state-manager';
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AddCustomInvestorGoalWizardModalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly state_manager!: CustomerContractedInvestmentProductsStateManager;

  @Inject(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly product_state_manager!: InvestmentProductsStateManager;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-wizard-modal';

  private investor_goals_dto: InvestorGoalsDto = {
    total_active_goals_balance: '',
    price_by_unit: '',
    recurrent_unassigned_amount: '',
    unassigned_balance: '',
    investor_goals: [],
  };

  STEPS = [
    'AddCustomInvestorGoalWizardPersonalGoal',
    'AddCustomInvestorGoalWizardCustomizeGoal',
    'AddCustomInvestorGoalWizardBeforeAdjustGoals',
    'AddCustomInvestorGoalWizardAdjustGoals',
  ];

  step_names: Array<any> = [];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  is_loading = false;

  custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: v4(),
    goal_name: '',
    desired_amount: 0,
    fixed_time_adjusted: '',
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
  }

  associated_product_name = '';

  associated_product_id = '';

  goals_selected: Array<string> = [];

  investor_goals: InvestorGoalsDto = {
    total_active_goals_balance: '0',
    price_by_unit: '0',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: [],
  };

  new_recurrent_amount = 0;

  goals_amount_information = [];

  private readonly view: any;

  constructor(view: any) {
    this.view = view;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.step_names = this.translate('steps');
    this.goals_selected.push(this.custom_investor_goal_dto.id);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  nextStep = () => {
    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.view.endProcess();
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.step_names = this.translate('steps');
    this.STEPS = this.STEPS.slice(0, 4);
    if (this.associated_product_id) {
      this.view.$emit('createdAndLinkedGoal', this.associated_product_name);
    }
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  setCustomGoalSelected = (custom_goal_types_entity: CustomGoalTypesEntity) => {
    this.custom_investor_goal_dto.goal_name = custom_goal_types_entity.label;
    this.custom_investor_goal_dto.custom_goal_type = custom_goal_types_entity;
  }

  setCustomGoalInvestorDto = (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    this.custom_investor_goal_dto = { ...custom_investor_goal_dto };
  }

  changeInvestorGoals = (investor_goals: InvestorGoalsDto) => {
    this.investor_goals = { ...investor_goals };
  }

  changeRecurrentAmount = (new_recurrent_amount: number) => {
    this.new_recurrent_amount = new_recurrent_amount;
  }

  changeRecurrentUnassignedAmount = (recurrent_unassigned_amount: string) => {
    this.investor_goals.recurrent_unassigned_amount = recurrent_unassigned_amount;
  }

  changeGoalsAmountInformation = (goals_information: []) => {
    this.goals_amount_information = goals_information;
  }

  changeDefineContributionLater = (define_contribution_later: boolean) => {
    if (define_contribution_later) {
      this.STEPS.pop();
      this.step_names.pop();
    } else {
      this.STEPS.push('StepSchedulePaymentDateWealth');
      this.step_names.push(this.translate('set_a_reminder'));
    }
  }

  setSteps = (link_steps: Array<string>) => {
    this.STEPS = this.STEPS.concat(link_steps);
    const investor_goal = {
      investor_goal_id: this.custom_investor_goal_dto.id,
      active_goal_amount: '0',
      recurrent_assigned_amount: '0',
    };
    this.investor_goals.investor_goals.push(investor_goal);
    this.nextStep();
  }

  addPocketLinkSteps = () => {
    this.associated_product_name = 'SOWOS Pocket';
    const pocket_product = this.product_state_manager.state.items.find(
      (product) => product.name === 'sowos_pocket',
    );
    if (pocket_product) {
      this.associated_product_id = pocket_product?.id;

      const link_steps = ['LinkGoal', 'StepDistributeBalancePocket', 'StepScheduleReminderPocket'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.step_names = this.step_names.concat(this.translate('link_steps_pocket'));
      this.setSteps(link_steps);
    }
  }

  addWealthLinkSteps = () => {
    this.associated_product_name = 'SOWOS Wealth';
    const wealth_product = this.product_state_manager.state.items.find(
      (product) => product.name === 'sowos_wealth',
    );
    if (wealth_product) {
      this.associated_product_id = wealth_product?.id;
      const link_steps = ['LinkGoal', 'StepDistributeBalanceWealth', 'StepConfigureContributions', 'StepSchedulePaymentDateWealth'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.step_names = this.step_names.concat(this.translate('link_steps_wealth'));
      this.setSteps(link_steps);
    }
  }

  validateContractedProducts = (): boolean => {
    if (this.state_manager.state.loaded
      && this.state_manager.state.item.has_any_product_contracted
      && this.custom_investor_goal_dto.fixed_time_adjusted
    ) {
      if (parseFloat(this.custom_investor_goal_dto.fixed_time_adjusted) < 12
        && this.state_manager.state.item.has_sowos_pocket_contracted) {
        this.addPocketLinkSteps();
        return true;
      }
      if (parseFloat(this.custom_investor_goal_dto.fixed_time_adjusted) >= 12
        && this.state_manager.state.item.has_sowos_wealth_contracted) {
        this.addWealthLinkSteps();
        return true;
      }
    }
    return false;
  }

  showSuccessModal = () => {
    this.view.$emit('createdGoal');
  }
}
