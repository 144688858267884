















































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-modal-view-model';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';

@Component({
  name: 'AddCustomInvestorGoalWizardModal',
  components: {
    AddCustomInvestorGoalWizardPersonalGoal: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardPersonalGoal.vue'),
    AddCustomInvestorGoalWizardCustomizeGoal: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardCustomizeGoal.vue'),
    AddCustomInvestorGoalWizardBeforeAdjustGoals: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardBeforeAdjustGoals.vue'),
    AddCustomInvestorGoalWizardAdjustGoals: () => import('@/vue-app/components/goals-dashboard/add-goals/AddCustomInvestorGoalWizardAdjustGoals.vue'),
    LinkGoal: () => import('@/vue-app/components/goals-dashboard/add-goals/link/LinkGoal.vue'),
    StepDistributeBalancePocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/StepDistributeBalancePocket.vue'),
    StepScheduleReminderPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/StepScheduleReminderPocket.vue'),
    StepDistributeBalanceWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepDistributeBalanceWealth.vue'),
    StepConfigureContributions: () => import('@/vue-app/components/goals-dashboard/add-goals/link/StepConfigureContributions.vue'),
    StepSchedulePaymentDateWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepSchedulePaymentDateWealth.vue'),
  },
})
export default class AddCustomInvestorGoalWizardModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  add_custom_goal_wizard_model = Vue.observable(
    new AddCustomInvestorGoalWizardModalViewModel(this),
  );

  endProcess() {
    this.synced_is_open = false;
    this.add_custom_goal_wizard_model.endProcess();
  }

  creationGoalFlowCompleted(custom_investor_goal_dto: CustomInvestorGoalDto) {
    this.add_custom_goal_wizard_model.setCustomGoalInvestorDto(custom_investor_goal_dto);
    const contracted_product = this.add_custom_goal_wizard_model.validateContractedProducts();
    if (!contracted_product) {
      this.add_custom_goal_wizard_model.showSuccessModal();
      this.synced_is_open = false;
    }
  }
}
